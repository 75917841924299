import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { LocaleDates } from '@/app/components/date/localeDate';
import { MissingLocaleDates } from '@/app/components/date/missingLocales/missingLocales';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

export async function getNowLocaleDate(languageCode?: string) {
  const language = languageCode ?? 'en';

  try {
    if (languageCode && languageCode in LocaleDates) {
      const file = LocaleDates[languageCode as keyof typeof LocaleDates];

      await file();
      dayjs.locale(language);
    } else if (languageCode && languageCode in MissingLocaleDates) {
      const file =
        MissingLocaleDates[languageCode as keyof typeof MissingLocaleDates];

      const lib = await file();
      const langLocale = lib.default(dayjs);
      dayjs.locale(langLocale, {}, true);
    } else {
      await import(`dayjs/locale/en`);
      dayjs.locale('en');
    }
  } catch {
    await import(`dayjs/locale/en`);
    dayjs.locale('en');
  }
  return dayjs();
}

export async function getLocaleDate(
  utcDate?: string | null,
  languageCode?: string,
) {
  if (!utcDate) return;
  if (new Date(utcDate).toString() === 'Invalid Date') {
    return;
  }
  const language = languageCode ?? 'en';

  try {
    if (languageCode && languageCode in LocaleDates) {
      const file = LocaleDates[languageCode as keyof typeof LocaleDates];
      await file();
      dayjs.locale(language);
    } else {
      await import(`dayjs/locale/en`);
      dayjs.locale('en');
    }
  } catch {
    await import(`dayjs/locale/en`);
    dayjs.locale('en');
  }
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return dayjs.utc(utcDate).tz(timezone);
}

export const fromNowDate = async (
  utcDate?: string | null,
  languageCode?: string,
) => {
  if (!utcDate) return '';
  if (new Date(utcDate).toString() === 'Invalid Date') {
    return '';
  }
  const language = languageCode ?? 'en';

  const now = await getNowLocaleDate(languageCode);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const date = dayjs.utc(utcDate).tz(timezone);

  if (now.diff(date, 'hours') >= 24) {
    // Display in localized date format
    return date.locale(language).format('LL');
  } else {
    return date.locale(language).fromNow();
  }
};
