export const MissingLocaleDates = {
  tat: () => import('./tat'),
  ps: () => import('./ps'),
  kir: () => import('./kir'),
  kk: () => import('./kk'),
  'fa-AF': () => import('./fa-AF'),
  so: () => import('./so'),
  pcm: () => import('./pcm'),
  ha: () => import('./ha'),
  bs: () => import('./bs'),
};
