import { Content } from '@/lib/model';
import { getContentMetadata } from '@/common/utils';
import { ArticleLink } from '@/app/components/link/ArticleLink';
import {
  PlatformAuthorName,
  PlatformMediaBox,
} from '@/app/components/cards/common';
import { useTranslations } from 'next-intl';

type Props = {
  content?: Content;
};

export function ExploreCard({ content }: Props) {
  const t = useTranslations();

  const { mobileContentMetadata, desktopContentMetadata } =
    getContentMetadata(content);
  return (
    <div className="w-full flex flex-row sm:flex-col gap-3 no-underline article-hover-effect">
      <div className="max-w-[100px] sm:max-w-none sm:aspect-auto aspect-square w-full h-fit rounded bg-no-repeat relative">
        <PlatformMediaBox
          mobileContentMetadata={mobileContentMetadata}
          desktopContentMetadata={desktopContentMetadata}
          href={`/article/${content?.slug}`}
          className="sm:h-auto rounded sm:w-full w-[100px] h-full"
          containerClassName="w-full h-full"
          alt={content?.title ?? 'image'}
          width={260}
          height={195}
          autoplay
        />
      </div>

      <div className="flex w-full flex-col gap-2">
        <ArticleLink
          articleSlug={content?.slug}
          className="font-playfair text-base lg:text-lg font-semibold text-grey-12 leading-custom_4 lg:leading-6 line-clamp-3"
        >
          {content?.title}
        </ArticleLink>
        <div className="font-playfair text-opinion-gold text-xs2 leading-[normal] font-semibold">
          <PlatformAuthorName
            mobileContentMetadata={mobileContentMetadata}
            desktopContentMetadata={desktopContentMetadata}
            prefix={t('by')}
          />
        </div>
      </div>
    </div>
  );
}
