import { Content } from '@/lib/model';
import { getContentMetadata } from '@/common/utils';
import { cn } from '@/lib/utils';
import {
  PlatformDescription,
  PlatformMediaBox,
  PlatformTitle,
} from '@/app/components/cards/common';
import { ArticleLink } from '@/app/components/link/ArticleLink';

type Props = {
  content?: Content;
  className?: string;
  titleClassName?: string;
  imageClassName?: string;
  imageContainerClassName?: string;
};

export function SingleCard({
  content,
  className,
  titleClassName,
  imageClassName,
  imageContainerClassName,
}: Props) {
  const { mobileContentMetadata, desktopContentMetadata } =
    getContentMetadata(content);
  return (
    <div
      className={cn(
        'w-full relative left-block__image-wrapper article-hover-effect lg:pt-2 pt-1 overflow-x-hidden',
        'border-b border-b-grey-8 last:border-b-0 overflow-hidden no-underline before:content-[""] before:absolute before:-top-3 before:h-[1px] xl:before:h-[0px] before:w-full before:bg-grey-5',
        className,
      )}
    >
      <ArticleLink
        articleSlug={content?.slug}
        className={cn(
          'w-full flex flex-col gap-3 order-2 sm:order-1',
          titleClassName,
        )}
      >
        <div className="font-playfair xl:text-1.5xl xl:leading-7 md:text-xl md:leading-6.5 lg:text-1.5xl lg:leading-7 sm:text-xl sm:leading-6.5 text-1.5xl leading-7 xl:font-extrabold font-semibold text-grey-11 normal">
          <PlatformTitle
            mobileContentMetadata={mobileContentMetadata}
            desktopContentMetadata={desktopContentMetadata}
          />
        </div>

        <div className="flex flex-col gap-2.5">
          <div className="font-notosans text-grey-8 text-base leading-normal line-clamp-4 sm:text-xs2 lg:text-sm xl:text-base lg:line-clamp-6 xl:line-clamp-4">
            <PlatformDescription
              mobileContentMetadata={mobileContentMetadata}
              desktopContentMetadata={desktopContentMetadata}
            />
          </div>
        </div>
      </ArticleLink>

      <div className={cn('w-full order-1 sm:order-2', imageContainerClassName)}>
        <PlatformMediaBox
          mobileContentMetadata={mobileContentMetadata}
          desktopContentMetadata={desktopContentMetadata}
          href={`/article/${content?.slug}`}
          className={cn('w-full rounded-custom', imageClassName)}
          alt={content?.title ?? 'info'}
          width={612}
          height={345}
          autoplay
          quality={90}
        />
      </div>
    </div>
  );
}
