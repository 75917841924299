import { ArticleLink } from '@/app/components/link/ArticleLink';

import { getContentMetadata } from '@/common/utils';
import { Content, Topic } from '@/lib/model';
import { PlatformMediaBox } from '@/app/components/cards/common';
import { LocaleUppercase } from '../../text/LocaleUppercase';

type Props = {
  content?: Content;
};

export function FeatureCard({ content }: Props) {
  const { mobileContentMetadata, desktopContentMetadata } =
    getContentMetadata(content);
  const slug = (content?.primaryTopics as Topic)?.page?.name;
  return (
    <div className="w-full flex flex-col md:flex-row gap-6 no-underline article-hover-effect">
      <div className="w-full md:w-7/12 xl:w-1/2">
        <PlatformMediaBox
          desktopContentMetadata={desktopContentMetadata}
          mobileContentMetadata={mobileContentMetadata}
          href={`/article/${content?.slug}`}
          className="w-full rounded"
          alt={content?.title}
          width={668}
          height={376}
          autoplay
        />
      </div>

      <ArticleLink
        articleSlug={content?.slug}
        className="w-full md:w-5/12 xl:w-1/2 flex flex-col gap-4"
      >
        {slug && typeof slug === 'string' ? (
          <div className="p-2 bg-[#ddc9c7] w-fit font-sf text-sm font-semibold uppercase leading-custom_1 tracking-wide text-black-11">
            <LocaleUppercase>{slug}</LocaleUppercase>
          </div>
        ) : null}

        <div className="font-playfair font-extrabold text-black-10 line-clamp-none md:line-clamp-4 lg:line-clamp-2 text-2xl md:text-xl xl:text-2.5xl leading-normal md:leading-6 xl:leading-custom_6">
          {content?.title}
        </div>

        <div className="flex flex-col gap-3">
          <div className="text-xl font-normal leading-8 text-grey-12 hidden lg:block font-notosans line-clamp-5">
            {content?.description}
          </div>
        </div>
      </ArticleLink>
    </div>
  );
}
