import { Content } from '@/lib/model';
import { cn } from '@/lib/utils';
import { FromNowDate } from '../../date';
import { LinkWithChannelServerLink } from '../../link/LinkWithChannelServer';

type Props = {
  content: Content;
  liveHref?: string;
  date?: string;
  className?: string;
  variant?: 'dark' | 'light';
};

export function LineCard({
  content,
  liveHref = '/live',
  className,
  variant = 'dark',
  date,
}: Props) {
  if (!content.slug) {
    return (
      <LinkWithChannelServerLink
        href={`${liveHref}`}
        className={'w-full flex flex-row gap-2.5 article-hover-effect'}
      >
        <ContentSection
          {...content}
          className={className}
          variant={variant}
          date={date}
        />
      </LinkWithChannelServerLink>
    );
  }
  return (
    <LinkWithChannelServerLink
      href={`${liveHref}/${content?.slug}`}
      className={'w-full flex flex-row gap-2.5 article-hover-effect'}
    >
      <ContentSection
        {...content}
        className={className}
        variant={variant}
        date={date}
      />
    </LinkWithChannelServerLink>
  );
}

const ContentSection = ({
  title,
  className,
  variant,
  date,
}: { title: string } & Pick<Props, 'className' | 'date' | 'variant'>) => {
  return (
    <>
      <div className="flex flex-col gap-1">
        <div
          className={cn('w-[7px] aspect-square rounded-full', {
            'bg-[#454545]': variant === 'dark',
            'bg-grey-6': variant === 'light',
          })}
        ></div>
        <div className="bg-grey-5 w-[1px] ms-[2.5px] h-3/4"></div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="font-sf text-xs font-medium text-grey-7 uppercase tracking-[1px] mt-[-4px]">
          <FromNowDate date={date} />
        </div>

        <div
          className={cn(
            'font-notosans text-xs2 leading-6 font-semibold text-[#303232]',
            className,
          )}
        >
          {title}
        </div>
      </div>
    </>
  );
};
