import { ArticleLink } from '@/app/components/link/ArticleLink';

import { Content } from '@/lib/model';
import { getContentMetadata } from '@/common/utils';
import {
  PlatformDescription,
  PlatformMediaBox,
  PlatformTitle,
} from '@/app/components/cards/common';

type Props = {
  content?: Content;
  showDescription?: boolean;
  quality?: number;
};

export function SnapCard({ content, showDescription, quality }: Props) {
  const { mobileContentMetadata, desktopContentMetadata } =
    getContentMetadata(content);
  return (
    <div className="w-full flex lg:flex-row flex-col gap-3 no-underline hover:opacity-70 hover:transition-opacity hover:duration-75 hover:ease-linear pb-5 border-b-grey-4 lg:border-b sm:border-b-0 border-b pt-5 first:pt-0 lg:pt-5 sm:pt-0 last:pb-0 last:border-none lg:border-r-0 sm:border-r sm:border-grey-4 lg:first:pe-0 sm:first:pe-5 lg:last:ps-0 sm:last:ps-5">
      <ArticleLink
        articleSlug={content?.slug}
        className="w-full flex flex-row gap-3"
      >
        <div className="flex flex-col sm:gap-2 gap-3 w-full">
          <div className="font-playfair text-base leading-5.5 font-semibold text-grey-11 flex justify-between items-start gap-[15px]">
            <PlatformTitle
              mobileContentMetadata={mobileContentMetadata}
              desktopContentMetadata={desktopContentMetadata}
            />
            <div className="sm:hidden block">
              <PlatformMediaBox
                mobileContentMetadata={mobileContentMetadata}
                desktopContentMetadata={desktopContentMetadata}
                className="rounded"
                containerClassName="w-[100px] h-[100px] aspect-square"
                alt={content?.title ?? 'image'}
                width={100}
                height={100}
                playVideo={false}
                quality={quality}
              />
            </div>
          </div>

          {showDescription && (
            <div className="hidden font-notosans text-xs2 text-grey-8 md:line-clamp-4">
              <PlatformDescription
                mobileContentMetadata={mobileContentMetadata}
                desktopContentMetadata={desktopContentMetadata}
              />
            </div>
          )}
        </div>
        <div className="sm:block hidden w-full aspect-square max-w-[100px]">
          <PlatformMediaBox
            mobileContentMetadata={mobileContentMetadata}
            desktopContentMetadata={desktopContentMetadata}
            className="w-full rounded"
            containerClassName="w-[100px] h-[100px] aspect-square"
            alt={content?.title ?? 'image'}
            width={100}
            height={100}
            playVideo={false}
            quality={quality}
          />
        </div>
      </ArticleLink>

      {showDescription && (
        <div className="md:hidden font-notosans text-xs2 text-grey-8 line-clamp-4">
          <PlatformDescription
            mobileContentMetadata={mobileContentMetadata}
            desktopContentMetadata={desktopContentMetadata}
          />
        </div>
      )}
    </div>
  );
}
