import React from 'react';
import { ArticleLink } from '@/app/components/link/ArticleLink';
import { Content } from '@/lib/model';
import { useTranslations } from 'next-intl';

type Props = {
  content?: Content;
  showDescription?: boolean;
  showRelatedTitle?: boolean;
};

export function GroupNewsCard({
  content,
  showDescription,
  showRelatedTitle,
}: Props) {
  const t = useTranslations();
  const renderRelatedTitle = () => {
    return (
      <div className="justify-start items-start gap-1.5 inline-flex">
        <div className="w-1 h-3.5 bg-grey-11 rounded-custom" />
        <div className="text-grey-11 text-base font-medium font-trt uppercase leading-4">
          {t('related')}
        </div>
      </div>
    );
  };
  return (
    <ArticleLink
      articleSlug={content?.slug}
      className="flex flex-col gap-3 no-underline article-hover-effect py-5 border-b border-b-sky-50 first:pt-0 last:pb-0 last:border-none"
    >
      <div className="font-notoserif flex-row text-xl font-semibold text-grey-11 leading-custom_5">
        {showRelatedTitle && renderRelatedTitle()}
        <div>{content?.title}</div>
      </div>

      <div className="flex flex-col gap-2.5">
        {content?.description && showDescription && (
          <div className="font-notosans text-xs2 text-grey-8 leading-normal">
            {content?.description}
          </div>
        )}

        <div className="font-sf text-xs font-medium uppercase text-grey-8 tracking-[1px]">
          {content?.readingTime?.text}
        </div>
      </div>
    </ArticleLink>
  );
}
