import { ArticleLink } from '@/app/components/link/ArticleLink';

import { getContentMetadata } from '@/common/utils';
import { Content } from '@/lib/model';
import {
  PlatformAuthorName,
  PlatformAuthorPicture,
  PlatformDescription,
  PlatformMediaBox,
  PlatformTitle,
} from '@/app/components/cards/common';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';

type Props = {
  content?: Content;
  showImage?: boolean;
  showDescription?: boolean;
  quality?: number;
  hideAuthorPicture?: boolean;
  titleClassName?: string;
  className?: string;
};

export function OpinionCard({
  content,
  showImage,
  showDescription,
  quality,
  hideAuthorPicture,
  className,
  titleClassName,
}: Props) {
  const t = useTranslations();

  const { mobileContentMetadata, desktopContentMetadata } =
    getContentMetadata(content);
  return (
    <div
      className={cn(
        'w-full gap-4 border-b-grey-4 lg:border-b sm:border-b-0 border-b first:pb-5 lg:pt-2 sm:pt-0 py-5 no-underline article-hover-effect lg:last:ps-0 sm:last:ps-5 lg:first:pe-0 sm:first:pe-5 lg:first:border-r-0 sm:first:border-r border-r-grey-4',
        className,
      )}
    >
      {showImage && (
        <PlatformMediaBox
          mobileContentMetadata={mobileContentMetadata}
          desktopContentMetadata={desktopContentMetadata}
          href={`/article/${content?.slug}`}
          className="w-full rounded order-2"
          alt="opinion"
          width={428}
          height={246}
          autoplay
          quality={quality}
          sizes="100vw"
        />
      )}

      <div className="flex flex-col gap-3 order-1">
        <div className="flex flex-row gap-3">
          {!hideAuthorPicture && (
            <PlatformAuthorPicture
              mobileContentMetadata={mobileContentMetadata}
              desktopContentMetadata={desktopContentMetadata}
              width={32}
              height={32}
              className="rounded-full w-8 h-8 border border-opinion-gold"
              alt="author"
              quality={60}
            />
          )}
          <div className="my-auto font-playfair text-sm2 leading-5 text-opinion-gold">
            <PlatformAuthorName
              mobileContentMetadata={mobileContentMetadata}
              desktopContentMetadata={desktopContentMetadata}
              prefix={hideAuthorPicture ? t('by') : undefined}
            />
          </div>
        </div>
        <ArticleLink
          articleSlug={content?.slug}
          className="flex flex-col gap-3 order-1"
        >
          <div
            className={cn(
              'font-arima md:text-xl md:leading-6.5 text-lg leading-6 font-bold text-grey-11',
              titleClassName,
            )}
          >
            <PlatformTitle
              mobileContentMetadata={mobileContentMetadata}
              desktopContentMetadata={desktopContentMetadata}
            />
          </div>
          {showDescription ? (
            <div className="font-notosans text-xs2 text-black-8">
              <PlatformDescription
                mobileContentMetadata={mobileContentMetadata}
                desktopContentMetadata={desktopContentMetadata}
              />
            </div>
          ) : null}
        </ArticleLink>
      </div>
    </div>
  );
}
