import React from 'react';
import { ArticleLink } from '@/app/components/link/ArticleLink';
import { Content } from '@/lib/model';

type Props = {
  content?: Content;
  showDescription?: boolean;
};

export function ItemCard({ content, showDescription = true }: Props) {
  return (
    <ArticleLink
      articleSlug={content?.slug}
      className="w-full flex flex-col gap-3 first:pt-0 py-4 md:py-5 border-b border-b-grey-4 last:border-none last:pb-0 no-underline article-hover-effect"
    >
      <div className="font-playfair text-grey-11 text-lg leading-normal font-medium md:text-xl md:leading-custom_5 line-clamp-2">
        {content?.title}
      </div>

      {content?.description && showDescription && (
        <div className="font-notosans text-grey-8 line-clamp-3 font-normal text-xs2 leading-normal sm:line-clamp-4 lg:line-clamp-5">
          {content.description}
        </div>
      )}
    </ArticleLink>
  );
}
