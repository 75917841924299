import { ArticleLink } from '@/app/components/link/ArticleLink';

import { Author, Content } from '@/lib/model';
import { getContentMetadata } from '@/common/utils';
import { useTranslations } from 'next-intl';
import { PlatformMediaBox } from '@/app/components/cards/common';
import { getAuthorLinkWithId } from '@/utils/links/author';
import { LinkWithChannelLink } from '../../link/LinkWithChannel';

type Props = {
  content?: Content;
};

export function LightCard({ content }: Props) {
  const t = useTranslations();
  const authors = [
    content?.author,
    ...(content?.otherAuthors || []),
  ] as Author[];
  const { mobileContentMetadata, desktopContentMetadata } =
    getContentMetadata(content);
  return (
    <div className="w-full flex flex-col gap-3 no-underline article-hover-effect">
      <PlatformMediaBox
        mobileContentMetadata={mobileContentMetadata}
        desktopContentMetadata={desktopContentMetadata}
        href={`/article/${content?.slug}`}
        className="w-full rounded"
        alt={content?.title ?? 'image'}
        width={260}
        height={158}
        playVideo={false}
        quality={70}
      />
      <ArticleLink
        articleSlug={content?.slug}
        className="flex flex-col gap-2.5"
      >
        <div className="flex flex-col gap-2">
          <div className="text-grey-11 text-xl font-medium font-notoserif leading-custom_5">
            {content?.title}
          </div>
          {authors?.map((author, index) => {
            const isFirst = index === 0;
            const isLast = index === authors?.length - 1;
            return (
              author?.id && (
                <LinkWithChannelLink
                  key={author.id}
                  href={getAuthorLinkWithId(author?.id)}
                  className="font-notoserif text-xs2 font-semibold text-gold line-clamp-3"
                >
                  {isFirst && t('by')} {author?.name}{' '}
                  {!isLast && authors?.length > 0 && t(', ')}
                </LinkWithChannelLink>
              )
            );
          })}

          <div className="text-grey-8 text-base font-notosans">
            {content?.description}
          </div>
        </div>

        <div className="text-grey-8 font-sf text-xs font-medium uppercase tracking-[0.6px]">
          {content?.readingTime?.text}
        </div>
      </ArticleLink>
    </div>
  );
}
