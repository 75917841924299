import React from 'react';
import { ArticleLink } from '@/app/components/link/ArticleLink';
import { Content } from '@/lib/model';
import { getContentMetadata } from '@/common/utils';
import { PlatformMediaBox } from '@/app/components/cards/common';

type Props = {
  content?: Content;
};

export function TopicCard({ content }: Props) {
  const { mobileContentMetadata, desktopContentMetadata } =
    getContentMetadata(content);
  return (
    <div className="w-full flex flex-col gap-4 xl:gap-5 no-underline article-hover-effect">
      <PlatformMediaBox
        mobileContentMetadata={mobileContentMetadata}
        desktopContentMetadata={desktopContentMetadata}
        href={`/article/${content?.slug}`}
        className="w-full rounded object-cover"
        alt={content?.title ?? 'image'}
        containerClassName="h-full w-full"
        width={336}
        height={252}
        autoplay
        quality={90}
      />
      <ArticleLink articleSlug={content?.slug} className="flex flex-col gap-2">
        <div className="font-playfair text-lg xl:text-xl font-semibold text-grey-2 line-clamp-2">
          {content?.title}
        </div>
      </ArticleLink>
    </div>
  );
}
