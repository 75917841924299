export const LocaleDates = {
  'en-af': () => import('dayjs/locale/en'),
  'fr-af': () => import('dayjs/locale/fr'),
  uz: () => import('dayjs/locale/uz'),
  uig: () => import('dayjs/locale/ug-cn'),
  tuk: () => import('dayjs/locale/tk'),
  ro: () => import('dayjs/locale/ro'),
  pt: () => import('dayjs/locale/pt'),
  it: () => import('dayjs/locale/it'),
  hu: () => import('dayjs/locale/hu'),
  el: () => import('dayjs/locale/el'),
  ka: () => import('dayjs/locale/ka'),
  zh: () => import('dayjs/locale/zh'),
  bg: () => import('dayjs/locale/bg'),
  az: () => import('dayjs/locale/az'),
  hy: () => import('dayjs/locale/hy-am'),
  ja: () => import('dayjs/locale/ja'),
  no: () => import('dayjs/locale/nb'), // "nb" (Norwegian Bokmål) used as replacement for "no"
  da: () => import('dayjs/locale/da'),
  pl: () => import('dayjs/locale/pl'),
  fi: () => import('dayjs/locale/fi'),
  id: () => import('dayjs/locale/id'),
  ms: () => import('dayjs/locale/ms'),
  nl: () => import('dayjs/locale/nl'),
  sv: () => import('dayjs/locale/sv'),
  ko: () => import('dayjs/locale/ko'),
  ur: () => import('dayjs/locale/ur'),
  sq: () => import('dayjs/locale/sq'),
  de: () => import('dayjs/locale/de'),
  ru: () => import('dayjs/locale/ru'),
  mk: () => import('dayjs/locale/mk'),
  sw: () => import('dayjs/locale/sw'),
  fa: () => import('dayjs/locale/fa'),
  'fa-AF': () => import('dayjs/locale/fa'),
  he: () => import('dayjs/locale/he'),
  hi: () => import('dayjs/locale/hi'),
  es: () => import('dayjs/locale/es'),
  ar: () => import('dayjs/locale/ar'),
  fr: () => import('dayjs/locale/fr'),
  en: () => import('dayjs/locale/en'),
  tr: () => import('dayjs/locale/tr'),
};
