import React from 'react';
import { Content } from '@/lib/model';
import { getContentMetadata } from '@/common/utils';
import { PlatformMediaBox } from '@/app/components/cards/common';
import { ArticleLink } from '@/app/components/link/ArticleLink';

type Props = {
  content?: Content;
};

export function LargeCard({ content }: Props) {
  const { mobileContentMetadata, desktopContentMetadata } =
    getContentMetadata(content);
  return (
    <div className="w-full flex flex-col gap-6 no-underline article-hover-effect">
      <div className="w-full">
        <PlatformMediaBox
          desktopContentMetadata={desktopContentMetadata}
          mobileContentMetadata={mobileContentMetadata}
          href={`/article/${content?.slug}`}
          className="w-full rounded"
          alt={content?.title}
          width={704}
          height={455}
          autoplay
          quality={90}
        />
      </div>

      <ArticleLink articleSlug={content?.slug} className="w-full">
        <div className="flex flex-col gap-4 xl:gap-3">
          <div className="font-playfair text-2xl xl:text-2xl font-medium xl:font-bold text-grey-11 xl:leading-7.5 leading-8">
            {content?.title}
          </div>

          <div className="font-notosans text-base text-grey-8 leading-normal">
            {content?.description}
          </div>
        </div>
      </ArticleLink>
    </div>
  );
}
