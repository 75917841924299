import { getChannelPref } from '@/i18n/userChannel';
import { Channel } from '@/lib/model';
import { generateChannelPath } from '@/utils';
import Link from 'next/link';
import { ComponentProps } from 'react';

type Props = ComponentProps<typeof Link>;

function getLinkWithChannelLink(channel: Channel, href: Props['href']) {
  return `/${generateChannelPath(channel)}${href}`;
}

export const LinkWithChannelServerLink = async (props: Props) => {
  const { channelPref } = await getChannelPref();
  if (!props.href) {
    return (
      <div style={props.style} className={props.className}>
        {props.children}
      </div>
    );
  }
  return (
    <Link {...props} href={getLinkWithChannelLink(channelPref, props.href)} />
  );
};
